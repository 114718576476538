import React from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import CardsContainer from "@global/cards/CardsContainer"
import { HelmetDatoCms } from "gatsby-source-datocms"
import Block from "@global/layout/Block"
import Cta from "@global/cards/Cta"
import Banner from "@global/layout/Banner"
import { ProtectedRoute } from "../components/ProtectedRoute"

const NotFoundPage = ({
  data: { allWorkoutContent, allRecipes, banner, content, seo },
  pageContext: { title, parent }
}) => {
  const links = [
    {
      title: "Workouts",
      image: allWorkoutContent.media[0].image,
      path: "/workouts"
    },
    {
      title: "Recipes",
      image: allRecipes.nodes[0].heroImage,
      path: "/recipes"
    },
    {
      title: "Challenges",
      image: banner.challengesBanner,
      path: "/challenges"
    }
  ]
  return (
    <Layout title={title} parent={parent}>
      <HelmetDatoCms
        title={`${content.pageNotFound.title}${seo.globalSeo.titleSuffix}`}
      />
      <Banner image={banner.pageNotFoundBanner} />
      <ProtectedRoute>
        <Block padding="top" gutters={true}>
          <h1>{content.pageNotFound.title}</h1>
          <p>{content.pageNotFound.message}</p>
        </Block>
        <Block padding="top" gutters={true}>
          <CardsContainer>
            {links.map(({ title, image, path }) => {
              return (
                <Cta
                  heading={title}
                  image={image}
                  link={{ title: `View  ${title}`, path: path }}
                  key={`notFound-${title}`}
                />
              )
            })}
          </CardsContainer>
        </Block>
      </ProtectedRoute>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  {
    banner: datoCmsGlobal {
      pageNotFoundBanner {
        gatsbyImageData(
          imgixParams: { w: "1280", h: "640", fit: "crop", auto: "true" }
        )
      }
      challengesBanner {
        gatsbyImageData(imgixParams: { fit: "crop", w: "664", h: "400" })
      }
    }
    content: contentYaml(language: { eq: "en-NZ" }) {
      pageNotFound {
        title
        message
      }
    }
    allWorkoutContent: datoCmsWorkout(path: { regex: "/speed-session/" }) {
      media {
        ... on DatoCmsWorkoutAudio {
          id
          image: banner {
            gatsbyImageData(imgixParams: { fit: "crop", w: "664", h: "400" })
          }
        }
      }
    }
    allRecipes: allDatoCmsRecipe(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 1
    ) {
      nodes {
        heroImage {
          gatsbyImageData(imgixParams: { fit: "crop", w: "664", h: "400" })
        }
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
  }
`
